<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Customer Responsibilities</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="consumerRespRequest.status"
                        label="Active"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Panel</label>
                    <v-combobox
                        v-model="consumerRespRequest.panelSelected"
                        :items="listConstructionPanel" 
                        :rules=[validations.required]
                        item-text="panelDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                        @change="filterStages()"
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Stage</label>
                    <v-combobox
                        v-model="consumerRespRequest.stageSelected"
                        :items="listConstructionStagesFiltered" 
                        :rules=[validations.required]
                        item-text="stageDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Description</label>
                    <v-textarea
                        v-model="consumerRespRequest.description"
                        rows="6"
                        row-height="30"
                        auto-grow
                        outlined
                    >
                    </v-textarea>
                </v-col>
            </v-row>
            
            <ActionButtons
                :request="consumerRespRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            prefix: 'mdi mdi-',

            consumerRespRequest: {
                id: 0,
                status: 1,
                idPanel: 0,
                idStage: 0,
                panelSelected: null,
                stageSelected: null,
                description: ""
            },

            listConstructionPanel: [],
            listConstructionStages: [],
            listConstructionStagesFiltered: [],

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            filterStages() {

                if (this.consumerRespRequest.panelSelected != null && this.consumerRespRequest.panelSelected != undefined) {
                    this.consumerRespRequest.stageSelected = null;
                    this.listConstructionStagesFiltered = this.listConstructionStages.filter(stage => stage.idPanel == this.consumerRespRequest.panelSelected.id);
                }
            },

            async getLists() {

                this.listConstructionPanel = await this.$store.dispatch("serviceModule/ListConstructionPanel");
                this.listConstructionStages = await this.$store.dispatch("serviceModule/ListConstructionStages");
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("consumerRespModule/GetById", this.id);

                    if (response.success) {
                        this.consumerRespRequest = response.result;

                        this.consumerRespRequest.panelSelected = {
                            id: this.consumerRespRequest.idPanel,
                            panelDescription: this.consumerRespRequest.panelDescription
                        }

                        await this.filterStages();

                        this.consumerRespRequest.stageSelected = {
                            id: this.consumerRespRequest.idStage,
                            stageDescription: this.consumerRespRequest.stageDescription
                        }
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/consumerResp/consumerRespList" });
            },
            
            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.consumerRespRequest.id = this.id;

                    if (this.consumerRespRequest.panelSelected != null && this.consumerRespRequest.panelSelected != undefined && this.consumerRespRequest.panelSelected.id != 0) {
                        this.consumerRespRequest.idPanel = this.consumerRespRequest.panelSelected.id;
                    }

                    if (this.consumerRespRequest.stageSelected != null && this.consumerRespRequest.stageSelected != undefined && this.consumerRespRequest.stageSelected.id != 0) {
                        this.consumerRespRequest.idStage = this.consumerRespRequest.stageSelected.id;
                    }

                    if (this.consumerRespRequest.status === null) {
                        this.consumerRespRequest.status = 0;
                    }

                    const result = await this.$store.dispatch("consumerRespModule/CreateUpdate", this.consumerRespRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();                        
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>